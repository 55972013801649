import { Document, Page, pdfjs } from "react-pdf";
import {
  IAMCertificationResponse,
  Iamport,
  SignInfo,
  signDto,
} from "../common/interface";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { dataURItoBlob } from "../common/convert";
import { getGPUInfo } from "../common/common";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import html2canvas from "html2canvas";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

declare global {
  interface Window {
    IMP?: Iamport;
  }
}

type SignProps = {
  id: string;
  info: SignInfo;
  onSubmit: (form: signDto) => void;
};

const SignPage = (props: SignProps) => {
  const { id, info, onSubmit } = props;

  const boxRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [isPdfOpen, setPdfOpen] = useState<number | null>(null);
  const [pdfTotalPage, setTotalPage] = useState(0);
  const [agreements, setAgreements] = useState<
    {
      required: boolean;
      agreement: boolean | null;
      marketing?: boolean;
    }[]
  >([]);

  const pdfDivRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const pdfView = (index: number) => {
    setPdfOpen(index);
  };

  const agreeClick = (index: number) => {
    setAgreements((prev) => {
      const newAgree = [...prev];
      newAgree[index].agreement = true;
      return newAgree;
    });
    setPdfOpen(null);
  };

  const handleRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    setAgreements((prev) => {
      const newAgree = [...prev];
      newAgree[i].agreement = e.target.value === "true";
      return newAgree;
    });
  };

  useEffect(() => {
    setAgreements(
      info.papers.map((p) => {
        return {
          required: p.required,
          agreement: null,
          marketing: p.marketing,
        };
      })
    );
  }, [info]);

  const handleSignClick = async () => {
    setLoading(true);
    try {
      let returnresult: signDto = {
        phone: info.phone,
        clientTime: new Date().toISOString(),
        screenResolution: `${window.screen.width}x${window.screen.height}x${window.devicePixelRatio}x${window.screen.colorDepth}`,
        systemInfo: "",
        signedPapers: info.papers.map((p, i) => {
          return {
            paperUUID: p.uuid,
            signed: agreements[i].agreement!,
          };
        }),
      };

      const nav = navigator as unknown as {
        deviceMemory: string | undefined;
      };

      returnresult.systemInfo = `${
        navigator.hardwareConcurrency
      }|${getGPUInfo()}|${
        typeof nav.deviceMemory !== "undefined" ? nav.deviceMemory : "nodata"
      }`;

      if (info.auth === "iamport") {
        // 본인인증 시작
        const { IMP } = window;
        IMP?.init(process.env.REACT_APP_IAM_MERCHANT_ID!);
        const result = await new Promise<IAMCertificationResponse>(
          (resolve, reject) => {
            IMP?.certification(
              {
                merchant_uid: id,
                phone: info.phone,
                name: info.name,
                popup: true,
              },
              (res) => {
                resolve(res);
              }
            );
          }
        );
        if (!result.success) {
          throw new Error(result.error_msg);
        }
        returnresult.iamUID = result.imp_uid;
        // 본인인증 끝
      }

      returnresult.marketingAgree = agreements.some(
        (a) => a.marketing === true && a.agreement === true
      );

      onSubmit(returnresult);
      setLoading(false);
    } catch (e) {
      const err = e as Error;

      enqueueSnackbar(`에러발생 : ${err.message}`, { variant: "error" });
      setLoading(false);

      throw e;
    }
  };

  const handleDownloadClick = async () => {
    if (isPdfOpen === null) {
      return;
    }

    if (info.papers[isPdfOpen].paperType === "pdf") {
      window.open(info.papers[isPdfOpen].paperURL, "_blank");
    } else if (boxRef.current) {
      const result = await html2canvas(boxRef.current, { scale: 3 });
      const blob = dataURItoBlob(result.toDataURL("image/jpeg"));
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${info.papers[isPdfOpen].name}.jpg`;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const checkSubmitDisabled = () => {
    if (info.papers.length !== agreements.length) {
      return true;
    }

    if (agreements.some((a) => a.agreement === null)) {
      return true;
    }

    if (agreements.some((a) => a.agreement === false && a.required === true)) {
      return true;
    }

    return false;
  };

  // pdf width 조정
  useEffect(() => {
    if (pdfDivRef.current) {
      setWidth(pdfDivRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (pdfDivRef.current) setWidth(pdfDivRef.current.offsetWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "450px",
          },
          marginTop: "20px",
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        <Typography variant="h4">고총무 계약서 작성</Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Box sx={{ display: "flex", gap: 4, flexDirection: "column" }}>
          {info.papers.map((paper, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                  gap: 4,
                }}
              >
                <div>
                  {index + 1}. {paper.name} ({paper.required ? "필수" : "선택"})
                </div>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => pdfView(index)}
                >
                  계약서 보기
                </Button>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {info.signed === false && (
                  <FormControl color="info">
                    <RadioGroup
                      row
                      value={
                        agreements[index] ? agreements[index].agreement : null
                      }
                      onChange={(e) => handleRadioChange(e, index)}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="동의"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="동의 안함"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </Box>
            </Box>
          ))}
        </Box>

        <Divider />
        <Box>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
            fullWidth
            disabled={checkSubmitDisabled()}
            onClick={handleSignClick}
          >
            {info.signed === true ? "이미 서명한 계약입니다" : "본인인증 서명"}
          </LoadingButton>
        </Box>
      </Box>
      <Dialog
        open={isPdfOpen !== null}
        onClose={() => setPdfOpen(null)}
        fullWidth
        maxWidth="lg"
      >
        {isPdfOpen !== null && (
          <>
            <DialogTitle>{info.papers[isPdfOpen].name}</DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
              {info.papers[isPdfOpen].paperType === "pdf" ? (
                <Box
                  sx={{ display: "flex", overflowX: "hidden" }}
                  ref={pdfDivRef}
                >
                  <Document
                    file={info.papers[isPdfOpen].paperURL}
                    options={{
                      cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                      cMapPacked: true,
                    }}
                    loading="문서 로드중..."
                    noData="데이터가 없습니다."
                    onLoadSuccess={(pdf) => setTotalPage(pdf.numPages)}
                  >
                    {Array.from(new Array(pdfTotalPage), (el, index) => (
                      <Page
                        width={width}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ))}
                  </Document>
                </Box>
              ) : (
                <Box sx={{ p: 4 }} ref={boxRef}>
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {info.papers[isPdfOpen].paperText}
                  </Markdown>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleDownloadClick}>
                다운로드
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => agreeClick(isPdfOpen)}
              >
                동의하기
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setPdfOpen(null)}
              >
                닫기
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js"
        integrity="sha512-GsLlZN/3F2ErC5ifS5QtgpiJtWd43JWSuIgh7mbzZ8zBps+dvLusV+eNQATqgA/HdeKFVgA5v3S/cIrLF7QnIg=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
    </Box>
  );
};

export default SignPage;

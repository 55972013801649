import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useState, KeyboardEvent, useRef, useEffect } from "react";
import AxiosClient from "../common/AxiosClient";
import { AxiosError } from "axios";
import { ErrorResponse } from "../common/interface";
import { useSnackbar } from "notistack";

type LoginProps = {
  onSubmit: (phone: string) => void;
  id: string;
};

const Login = (props: LoginProps) => {
  const { id, onSubmit } = props;
  const [mode, setMode] = useState<"phone" | "auth">("phone");

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [notiOpen, setNotiOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const codeRef = useRef<HTMLInputElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes("-")) {
      setNotiOpen(true);
      return false;
    }
    setPhone(e.target.value);
  };
  const handlePhoneKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onPhoneSubmit();
    }
  };

  const handleAuthKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onPhoneSubmit();
    }
  };

  const onPhoneSubmit = async () => {
    if (phone.length !== 11) {
      enqueueSnackbar("전화번호를 확인해주세요", { variant: "error" });
      return false;
    }
    try {
      setLoading(true);
      if (mode === "phone") {
        const result = await AxiosClient.post<{ success: boolean }>(
          "contract/sign/auth/request",
          {
            phone: phone,
          },
          { params: { uuid: id } }
        );

        if (result.data.success === true) {
          setMode("auth");
        } else {
          enqueueSnackbar("인증번호 발송에 실패했습니다.", {
            variant: "error",
          });
        }
      } else {
        const result = await AxiosClient.post(
          "contract/sign/auth/verify",
          {
            code: code,
          },
          { params: { uuid: id } }
        );

        if (result.data.success === true) {
          onSubmit(phone);
        } else {
          enqueueSnackbar("인증번호가 일치하지 않습니다.", {
            variant: "error",
          });
        }
      }
    } catch (e) {
      const err = e as AxiosError;
      const data = err.response?.data as ErrorResponse;
      enqueueSnackbar(data.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mode === "auth" && codeRef.current) codeRef.current.focus();
  }, [mode]);

  return (
    <Box>
      <Dialog open={true} fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>본인확인</DialogTitle>
        <DialogContent sx={{ textAlign: "center", width: { sm: "100%" } }}>
          <Typography sx={{ mb: 3 }}>
            계약자의 전화번호를 입력해주세요
          </Typography>
          <TextField
            disabled={mode === "auth"}
            fullWidth
            value={phone}
            onChange={handlePhoneChange}
            onKeyDown={handlePhoneKeyDown}
            label="전화번호"
            type="tel"
            inputMode="numeric"
            placeholder="-를 제외한 숫자만 입력해주세요"
          />
          {mode === "auth" && (
            <TextField
              inputRef={codeRef}
              fullWidth
              value={code}
              sx={{ mt: 2 }}
              onChange={(e) => setCode(e.target.value)}
              onKeyDown={handleAuthKeyDown}
              label="인증번호"
              inputMode="numeric"
              placeholder="문자로 발송된 인증번호를 입력해주세요."
            />
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={onPhoneSubmit}
            fullWidth
          >
            확인
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={notiOpen}
        autoHideDuration={3000}
        onClose={() => setNotiOpen(false)}
      >
        <Alert severity="warning">-를 제외한 숫자만 입력해주세요</Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;

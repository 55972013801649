import axios from "axios";

const AxiosClient = axios.create();
const URL = process.env.REACT_APP_APIURL;
const baseURL = `${URL}/user`;

// axios 글로벌 설정
AxiosClient.defaults.url = URL;
AxiosClient.defaults.baseURL = baseURL;
AxiosClient.defaults.withCredentials = true;

export default AxiosClient;

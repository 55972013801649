import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReInputBoolean, ReInputDto, SignInfo } from "../common/interface";

interface AdditInfoDialogProps {
  info: SignInfo;
  phone: string;
  reinput: ReInputBoolean;
  onSubmit: (data: ReInputDto) => void;
}

const AdditInfoDialog = (props: AdditInfoDialogProps) => {
  const { reinput, onSubmit, phone, info } = props;
  const { control, handleSubmit } = useForm<ReInputDto>();
  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = (dto: ReInputDto) => {
    if (reinput.email && !dto.email) {
      enqueueSnackbar("이메일을 입력해주세요", { variant: "warning" });
      return;
    }
    if (reinput.emergencyPhone && !dto.emergencyPhone) {
      enqueueSnackbar("비상연락망을 입력해주세요", { variant: "warning" });
      return;
    }
    if (reinput.home && !dto.home) {
      enqueueSnackbar("자택주소를 입력해주세요", { variant: "warning" });
      return;
    }
    if (reinput.cashReceiptTarget && !dto.cashReceiptTarget) {
      enqueueSnackbar("현금영수증 발행대상을 입력해주세요.", {
        variant: "warning",
      });
      return;
    }
    if (reinput.returnbank && !dto.returnbank) {
      enqueueSnackbar("환불계좌은행을 입력해주세요", { variant: "warning" });
      return;
    }
    if (reinput.returnbankNumber && !dto.returnbankNumber) {
      enqueueSnackbar("환불계좌번호를 입력해주세요", { variant: "warning" });
      return;
    }
    if (reinput.returnbankOwner && !dto.returnbankOwner) {
      enqueueSnackbar("환불계좌명을 입력해주세요", { variant: "warning" });
      return;
    }

    dto.phone = phone;
    onSubmit(dto);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: {
            sm: "100%",
            md: "450px",
          },
          marginTop: "20px",
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        <Typography variant="h4">추가정보 입력</Typography>
        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
          {info.name} 계약에 필요한 추가정보를 입력해주세요.
        </Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {reinput.email && (
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    variant="outlined"
                    fullWidth
                    type="email"
                    placeholder="example@example.com"
                    label="이메일"
                    helperText="계약서 파일 전송 및 현금영수증 발송에 사용됩니다."
                  />
                )}
              />
            )}
            {reinput.emergencyPhone && (
              <Controller
                control={control}
                name="emergencyPhone"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    type="tel"
                    variant="outlined"
                    fullWidth
                    placeholder="010-1234-5678"
                    label="비상연락처"
                  />
                )}
              />
            )}
            {reinput.home && (
              <Controller
                control={control}
                name="home"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    variant="outlined"
                    fullWidth
                    placeholder="주소"
                    label="자택주소"
                  />
                )}
              />
            )}
            {(reinput.returnbank ||
              reinput.returnbankNumber ||
              reinput.returnbankOwner) && (
              <>
                <Typography variant="h6" sx={{ mb: 0 }}>
                  보증금 환불계좌 정보
                </Typography>
                <Controller
                  control={control}
                  name="returnbank"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="한국은행"
                      label="은행이름"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="returnbankOwner"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="홍길동"
                      label="계좌명"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="returnbankNumber"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="123456-12-12345"
                      label="계좌번호"
                    />
                  )}
                />
              </>
            )}
            {reinput.cashReceiptTarget && (
              <Controller
                control={control}
                name="cashReceiptTarget"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="숫자만 입력"
                    label="현금영수증 발행대상"
                  />
                )}
              />
            )}
            <Button variant="contained" fullWidth type="submit">
              제출
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AdditInfoDialog;

import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AxiosClient from "../common/AxiosClient";
import { AxiosError } from "axios";
import { ErrorResponse } from "../common/interface";
import { useSnackbar } from "notistack";

const RequestPage = () => {
  const { uuid, type } = useParams<{ uuid: string; type: string }>();

  const [method, setMethod] = useState<"extend" | "terminate" | "">("");
  const [completed, setCompleted] = useState(false);

  const [extendLoading, setExtendLoading] = useState(false);
  const [terminateLoading, setTerminateLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (uuid) {
      return;
    }
  }, [uuid]);

  useEffect(() => {
    if (type === "extend") {
      setMethod("extend");
    } else if (type === "terminate") {
      setMethod("terminate");
    }
  }, [type]);

  const handleCancelClick = () => {
    if (type !== "extend" && type !== "terminate") {
      setMethod("");
    } else window.close();
  };

  const handleExtendClick = async () => {
    try {
      setExtendLoading(true);
      await AxiosClient.post(`/contract/${uuid}/extendreq`);
      setCompleted(true);
    } catch (e) {
      if (e instanceof AxiosError) {
        const err = e.response?.data as ErrorResponse;
        enqueueSnackbar(err.message, { variant: "error" });
      } else {
        enqueueSnackbar("일반오류, 관리자에게 문의해주세요.", {
          variant: "error",
        });
      }
    } finally {
      setExtendLoading(false);
    }
  };

  const handleTerminateClick = async () => {
    try {
      setTerminateLoading(true);
      await AxiosClient.post(`/contract/${uuid}/terminatereq`);
      setCompleted(true);
    } catch (e) {
      if (e instanceof AxiosError) {
        const err = e.response?.data as ErrorResponse;
        enqueueSnackbar(err.message, { variant: "error" });
      } else {
        enqueueSnackbar("일반오류, 관리자에게 문의해주세요.", {
          variant: "error",
        });
      }
    } finally {
      setTerminateLoading(false);
    }
  };

  if (!uuid) {
    return <NotFound />;
  }

  if (completed === true) {
    return (
      <Dialog open fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>
          요청이 완료되었습니다.
        </DialogTitle>
        <DialogContentText sx={{ p: 5 }}>
          요청이 정상적으로 처리되었습니다.
        </DialogContentText>
      </Dialog>
    );
  }

  if (method === "extend") {
    return (
      <Dialog open fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>계약 연장 확인</DialogTitle>
        <DialogContentText sx={{ p: 5 }}>
          정말로 계약을 연장하시겠습니까?
        </DialogContentText>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleCancelClick}>
            취소
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleExtendClick}
            loading={extendLoading}
          >
            연장
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  } else if (method === "terminate") {
    return (
      <Dialog open fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>퇴실 확인</DialogTitle>
        <DialogContentText sx={{ p: 5 }}>
          정말로 퇴실을 진행하시겠습니까?
        </DialogContentText>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="contained" color="error" onClick={handleCancelClick}>
            취소
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleTerminateClick}
            loading={terminateLoading}
          >
            퇴실 진행
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>연장/퇴실 선택</DialogTitle>
      <DialogContentText sx={{ p: 5 }}>
        계약을 연장하시려면 "연장" 버튼을 <br />
        퇴실하시려면 "퇴실" 버튼을 눌러주세요.
      </DialogContentText>
      <DialogActions sx={{ justifyContent: "center" }}>
        <LoadingButton variant="contained" onClick={() => setMethod("extend")}>
          연장
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={() => setMethod("terminate")}
          color="error"
        >
          퇴실
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RequestPage;

import { CloudUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { AxiosError } from "axios";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AxiosClient from "../common/AxiosClient";
import {
  ErrorResponse,
  GeneralResponse,
  TerminateInfo,
  TerminateSubmitDto,
} from "../common/interface";

const TerminatePage = ({ id }: { id: string }) => {
  const [isLoading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [additOpen, setAdditOpen] = useState(false);
  const { control, handleSubmit, setValue } = useForm<TerminateSubmitDto>({});
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const onSubmit = async (dto: TerminateSubmitDto) => {
    setAdditOpen(false);

    const data = new FormData();
    data.append("phone", dto.phone);
    data.append("returnBankAccount", dto.returnBankAccount);
    data.append("returnBankName", dto.returnBankName);
    data.append("returnBankAccountOwner", dto.returnBankAccountOwner);

    if (imageInputRef.current && imageInputRef.current.files) {
      for (let i = 0; i <= imageInputRef.current.files.length - 1; i++) {
        data.append("images", imageInputRef.current.files[i]);
      }
    }

    doSubmit(data);
  };

  const doSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      const { data: resdata } = await AxiosClient.post<GeneralResponse>(
        `/contract/terminate/${id}/submit`,
        data
      );

      // if (resdata.status === "needoverwrite") {
      //   try {
      //     await confirm({
      //       title: "중복 제출 확인",
      //       description:
      //         "이미 제출 된 이미지가 있습니다. 제출을 진행하시면 이전에 전송된 이미지는 삭제되고 이번에 전송한 이미지로 대체됩니다. 진행하시겠습니까?",
      //       allowClose: false,
      //     });
      //     data.append("overwrite", "true");
      //     doSubmit(data);
      //   } catch (e) {
      //     setLoading(false);
      //   }
      //   return;
      // }

      setCompleted(true);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e instanceof AxiosError) {
        const err = e.response?.data as ErrorResponse;
        if (err.message) {
          enqueueSnackbar(`에러발생 : ${err.message}`, { variant: "error" });
        } else {
          enqueueSnackbar("에러발생", { variant: "error" });
          throw e;
        }
      } else {
        enqueueSnackbar("에러발생", { variant: "error" });
        throw e;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFakeInputClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const handleFirstSubmit = () => {
    setAdditOpen(true);
  };

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages = [];
      for (let i = 0; i <= files.length - 1; i++) {
        const file = files[i];
        newImages.push(URL.createObjectURL(file));
      }
      setImages(newImages);
    }
  };

  if (completed === true) {
    return (
      <Box sx={{ height: "100vh", display: "flex", alignItmes: "center" }}>
        <Container maxWidth="sm" sx={{ height: "90vh", alignSelf: "center" }}>
          <Paper
            sx={{
              height: "100%",
              p: 2,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Typography textAlign="center" variant="h4">
              퇴실 사진 제출 완료
            </Typography>
            <p style={{ textAlign: "center" }}>
              관리자 확인 후 연락드리겠습니다.
            </p>
          </Paper>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100vh", display: "flex", alignItmes: "center" }}>
      <Container maxWidth="sm" sx={{ height: "90vh", alignSelf: "center" }}>
        <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Paper
            sx={{
              height: "100%",
              p: 2,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Typography variant="h4" textAlign="center">
              퇴실 사진 등록
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Box sx={{ height: "100%" }}>
              {images.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "1/1",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "5px black dashed",
                    cursor: "pointer",
                    mb: 2,
                  }}
                  onClick={handleFakeInputClick}
                >
                  <CloudUpload sx={{ fontSize: 100 }} />
                  파일 업로드
                  <Typography variant="caption">
                    *jpg, png파일 지원, 최대 10개, 15MB 크기제한
                  </Typography>
                </Box>
              ) : (
                <ImageList
                  sx={{ width: 500, height: 450 }}
                  cols={3}
                  rowHeight={164}
                >
                  {images.map((v) => (
                    <ImageListItem key={v}>
                      <img src={v} />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </Box>
            <Divider />
            <Box sx={{ mt: 5 }}>
              <Button
                sx={{ mb: 1 }}
                variant="contained"
                color="info"
                fullWidth
                onClick={handleFakeInputClick}
              >
                이미지 재선택
              </Button>
              <Button onClick={handleFirstSubmit} variant="contained" fullWidth>
                제출
              </Button>
            </Box>
          </Paper>
          <input
            ref={imageInputRef}
            style={{ display: "none" }}
            type="file"
            accept="image/jpeg, image/png"
            multiple
            onChange={handleChangeImage}
          />
          <Dialog open={additOpen} fullWidth maxWidth="md">
            <DialogTitle>추가정보입력</DialogTitle>
            <DialogContent>
              <Typography sx={{ mb: 2 }} variant="subtitle2">
                계약시 사용한 전화번호
              </Typography>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    label="전화번호"
                    placeholder="010-1234-5678"
                  />
                )}
              />
              <Typography sx={{ mb: 2 }} variant="subtitle2">
                보증금 환급 은행
              </Typography>
              <Controller
                control={control}
                name="returnBankName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    label="은행명"
                    placeholder="**은행"
                  />
                )}
              />
              <Typography sx={{ mb: 2 }} variant="subtitle2">
                보증금 환급 계좌번호
              </Typography>
              <Controller
                control={control}
                name="returnBankAccount"
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    label="계좌번호"
                    placeholder="12-345-67890"
                  />
                )}
              />
              <Typography sx={{ mb: 2 }} variant="subtitle2">
                보증금 환급 계좌명
              </Typography>
              <Controller
                control={control}
                name="returnBankAccountOwner"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="계좌명"
                    placeholder="계좌명"
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isLoading}
                variant="contained"
                type="submit"
                onClick={() => handleSubmit(onSubmit)()}
              >
                제출
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
      <Backdrop open={isLoading}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "white",
          }}
        >
          <CircularProgress />
          업로드중, 이미지 파일에 따라 시간이 소요될 수 있습니다.
        </Box>
      </Backdrop>
    </Box>
  );
};
export default TerminatePage;

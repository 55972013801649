export const getGPUInfo = () => {
  let gpu = "";
  const gl = document.createElement("canvas").getContext("webgl");
  if (gl) {
    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    if (debugInfo) {
      gpu = `${gl.getParameter(
        debugInfo.UNMASKED_VENDOR_WEBGL
      )} ${gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)}`;
    } else gpu = "nodata";
  } else gpu = "nodata";

  return gpu;
};

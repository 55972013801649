import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AxiosClient from "../common/AxiosClient";
import {
  ErrorResponse,
  ReInputDto,
  signDto,
  SignInfo,
  signResponse,
} from "../common/interface";
import ConfirmUser from "../component/ConfirmUser";
import { useLoading } from "../component/Loading";
import Login from "../component/Login";
import Sign from "./Sign";
import AdditInfoDialog from "../component/AdditInfoDialog";

const ContractPage = ({ id }: { id: string }) => {
  const [info, setInfo] = useState<SignInfo>();
  const [confirm, setConfirm] = useState(false);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const fetchSignInfo = async () => {
    try {
      setLoading(true);
      const { data } = await AxiosClient.get<SignInfo>("contract/sign/info", {
        params: { uuid: id },
      });
      setInfo(data);
      setLoading(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        const err = e.response?.data as ErrorResponse;
        enqueueSnackbar(err.message, { variant: "error" });
      } else {
        enqueueSnackbar("일반오류, 관리자에게 문의해주세요.", {
          variant: "error",
        });
      }
      setLoading(false);
    }
  };

  const onLoginSubmit = async (phone: string) => {
    fetchSignInfo();
  };

  const handleSignSubmit = async (form: signDto) => {
    try {
      setLoading(true);
      const { data } = await AxiosClient.post<signResponse>(
        `/contract/sign`,
        form,
        {
          params: { uuid: id },
        }
      );
      setLoading(false);
      navigate("/complete");
    } catch (e) {
      setLoading(false);
      if (e instanceof AxiosError) {
        const err = e.response?.data as ErrorResponse;
        enqueueSnackbar(`에러발생 : ${err.message}`, { variant: "error" });
      } else {
        const err = e as Error;
        enqueueSnackbar(`에러발생 : ${err.message}`, { variant: "error" });
      }
    }
  };

  const handleReinputSubmit = async (dto: ReInputDto) => {
    try {
      setLoading(true);
      await AxiosClient.post(`/contract/sign/additional`, dto, {
        params: { uuid: id },
      });
      setLoading(false);

      fetchSignInfo();
    } catch (e) {
      setLoading(false);
      if (e instanceof AxiosError) {
        const err = e.response?.data as ErrorResponse;
        enqueueSnackbar(`에러발생 : ${err.message}`, { variant: "error" });
      } else {
        const err = e as Error;
        enqueueSnackbar(`에러발생 : ${err.message}`, { variant: "error" });
      }
    }
  };

  if (!info) {
    return <Login id={id} onSubmit={onLoginSubmit} />;
  }
  if (!confirm) {
    return <ConfirmUser name={info.name} onSubmit={() => setConfirm(true)} />;
  }

  if (
    info.reinput.cashReceiptTarget === true ||
    info.reinput.email === true ||
    info.reinput.emergencyPhone === true ||
    info.reinput.home === true ||
    info.reinput.returnbank === true ||
    info.reinput.returnbankNumber === true ||
    info.reinput.returnbankOwner === true
  ) {
    return (
      <AdditInfoDialog
        info={info}
        phone={info.phone}
        reinput={info.reinput}
        onSubmit={handleReinputSubmit}
      />
    );
  }
  return <Sign id={id} info={info} onSubmit={handleSignSubmit} />;
};
export default ContractPage;

import * as queryString from "query-string";
import { useState } from "react";
import NotFound from "./pages/NotFound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ContractPage from "./pages/Contract";
import { useLoading } from "./component/Loading";
import CompletePage from "./pages/Complete";
import TerminatePage from "./pages/TerminatePage";
import RequestPage from "./pages/RequestPage";
import TerminateUpdatePage from "./pages/TerminateUpdate";

const App = () => {
  const param = queryString.parse(window.location.search);
  const { loading } = useLoading();
  const id = param.id as string;

  return (
    <BrowserRouter>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Routes>
        <Route path="/contract" element={<ContractPage id={id} />} />
        <Route path="/terminate" element={<TerminatePage id={id} />} />
        <Route path="/complete" element={<CompletePage />} />
        <Route path="/request/:uuid" element={<RequestPage />} />
        <Route path="/request/:uuid/:type" element={<RequestPage />} />
        <Route
          path="/terminate/update"
          element={<TerminateUpdatePage id={id} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { SignInfo } from "../common/interface";

type ConfirmUserProps = {
  name: string;
  onSubmit: () => void;
};

const ConfirmUser = (props: ConfirmUserProps) => {
  const { name, onSubmit } = props;
  return (
    <Dialog open>
      <DialogTitle sx={{ textAlign: "center" }}>본인확인</DialogTitle>
      <DialogContent>
        <Typography sx={{ textAlign: "center", mb: 2 }} variant="h4">
          {name}
        </Typography>
        고객님이 맞으신가요?
        <br />
        만약 본인이 아니시라면 취소 후 관리자에게 문의해주세요
      </DialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth onClick={onSubmit}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUser;

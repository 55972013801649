import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { LoadingProvider } from "./component/Loading";
import "./index.css";
import theme from "./theme/theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/ko";

dayjs.locale("ko");

const container = document.getElementById("root");
const root = createRoot(container!);

Sentry.init({
  dsn: "https://9ae0cb12dc23474bb449a406588722ae@o1383861.ingest.sentry.io/4504252208513024",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider>
      <LoadingProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfirmProvider
            defaultOptions={{
              confirmationText: "확인",
              cancellationText: "취소",
            }}
          >
            <App />
          </ConfirmProvider>
        </LocalizationProvider>
      </LoadingProvider>
    </SnackbarProvider>
  </ThemeProvider>
);

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import AxiosClient from "../common/AxiosClient";
import { UserTerminateInfoResponse } from "../common/interface";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";

const TerminateUpdatePage = ({ id }: { id: string }) => {
  const [date, setDate] = useState<Dayjs | null>(null);

  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<UserTerminateInfoResponse | null>(null);
  const [confirmModal, setConfirmModal] = useState<"cancel" | "update" | null>(
    null
  );
  const [resultModal, setResultModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleDateChange = (date: Dayjs | null) => {
    setDate(date);
  };

  const handlePhoneKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      phoneSubmit();
    }
  };

  const phoneSubmit = async () => {
    try {
      setLoading(true);
      const result = await AxiosClient.get<UserTerminateInfoResponse>(
        "contract/terminate/info",
        {
          params: { phone, uuid: id },
        }
      );
      setInfo(result.data);
      setDate(dayjs(result.data.terminateDate));
    } catch (e) {
      enqueueSnackbar(
        "해당 계약을 찾을 수 없습니다. 정확한 정보인지 확인해주세요.",
        { variant: "error" }
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClick = () => {
    if (!date) {
      enqueueSnackbar("퇴실일을 선택해주세요.", { variant: "error" });
      return;
    }

    if (info) {
      if (date.isSame(info.endDate, "d")) {
        enqueueSnackbar("퇴실일이 변경되지 않았습니다.", {
          variant: "warning",
        });
        return;
      }
      setConfirmModal("update");
    }
  };

  const handleUpdate = async () => {
    if (!date) return;

    try {
      setLoading(true);
      await AxiosClient.patch(`contract/terminate/${id}`, {
        terminateDate: date.format("YYYY-MM-DD"),
      });
      setResultModal(true);
    } catch (e) {
      enqueueSnackbar("요청을 처리할 수 없습니다. 담당자에게 문의해주세요.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await AxiosClient.delete(`contract/terminate/${id}`);
      setResultModal(true);
    } catch (e) {
      enqueueSnackbar("요청을 처리할 수 없습니다. 담당자에게 문의해주세요.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (info === null) {
    return (
      <Dialog open maxWidth="xs" fullWidth>
        <DialogTitle>퇴실 수정 본인확인</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            계약자의 전화번호를 입력해주세요
          </Typography>
          <TextField
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            onKeyDown={handlePhoneKeyDown}
            label="전화번호"
            type="tel"
            inputMode="numeric"
            placeholder="-를 제외한 숫자만 입력해주세요"
          />
          <LoadingButton
            loading={loading}
            sx={{ mt: 2 }}
            fullWidth
            variant="contained"
            onClick={phoneSubmit}
          >
            확인
          </LoadingButton>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>
        {/* 퇴실 취소 및 변경하기 */}
        퇴실 취소하기
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
            <Typography>퇴실자 : </Typography>
            <Typography>계약일 : </Typography>
            <Typography>퇴실일 : </Typography>
          </Grid>

          <Grid item sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
            <Typography>
              {info.name} - {info.room}
            </Typography>
            <Typography>
              {dayjs(info.startDate).format("YYYY-MM-DD")} ~{" "}
              {dayjs(info.endDate).format("YYYY-MM-DD")}
            </Typography>
            <Typography>
              {dayjs(info.terminateDate).format("YYYY-MM-DD")}
            </Typography>
            {/* <Typography>
              <DatePicker
                value={date}
                inputFormat="YYYY-MM-DD"
                mask="____-__-__"
                onChange={handleDateChange}
                renderInput={(p) => (
                  <TextField variant="standard" size="small" {...p} />
                )}
              />
            </Typography> */}
          </Grid>
        </Grid>
        {date !== null && date.isAfter(info.endDate, "d") && (
          <Typography sx={{ display: "block", mt: 2 }} variant="caption">
            * 퇴실일이 계약일보다 이후 일 경우, 추가 금액이 발생합니다.
          </Typography>
        )}
        {date !== null && date.isBefore(info.endDate, "d") && (
          <Typography sx={{ display: "block", mt: 1 }} variant="caption">
            * 퇴실일이 계약일보다 빠릅니다. 계약일 이전에 퇴실하셔도, 이미
            납부하신 월 입실료에서 환급을 받지 못할 수 있습니다.
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, gap: 3 }}>
          <LoadingButton
            loading={loading}
            color="error"
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => setConfirmModal("cancel")}
          >
            퇴실 취소
          </LoadingButton>
          {/* <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleUpdateClick}
          >
            퇴실 변경
          </LoadingButton> */}
        </Box>
      </DialogContent>
      <Dialog open={confirmModal !== null}>
        {confirmModal === "cancel" && (
          <>
            <DialogTitle>요청한 퇴실을 취소하시겠습니까?</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  퇴실 취소
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => setConfirmModal(null)}
                >
                  아니요
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
        {confirmModal === "update" && (
          <>
            <DialogTitle>퇴실일을 변경하시겠습니까?</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 4,
                }}
              >
                <Button variant="contained" onClick={handleUpdate}>
                  퇴실일 변경
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setConfirmModal(null)}
                >
                  아니요
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
      <Dialog open={resultModal}>
        <DialogTitle>변경 완료</DialogTitle>
      </Dialog>
    </Dialog>
  );
};

export default TerminateUpdatePage;

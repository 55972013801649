import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CompletePage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
    >
      <Paper sx={{ p: 2, pb: 4, textAlign: "center", px: 10 }} elevation={3}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          my={4}
          mb={6}
        >
          계약 서명이 완료되었습니다.
        </Typography>
        <Typography>알림톡 / 문자로 관련 정보가 전송될 예정입니다.</Typography>
        <br />
        <Typography>
          언제든 이 URL로 다시 접속하셔서 계약서를 볼 수 있습니다.
        </Typography>
      </Paper>
    </Box>
  );
};

export default CompletePage;

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const NotFound = () => {
  return (
    <Dialog open>
      <DialogTitle sx={{ textAlign: "center" }}>
        비정상적인 접근입니다.
      </DialogTitle>
      <DialogContentText sx={{ p: 5 }}>
        접속 URL을 다시 확인해주시고, 문제가 지속되면 관리자에게 문의해주세요.
      </DialogContentText>
    </Dialog>
  );
};

export default NotFound;
